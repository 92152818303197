import type {
  CountryCode,
  CurrencyCode,
  MoneyV2,
} from '@shopify/hydrogen/storefront-api-types';
import fx from 'money';
import {currencyDetails} from '~/data/currencies';

export interface Currency {
  _key: string;
  _type: string;
  currency: string;
  exchange_rates: ExchangeRate[];
  details: {
    country: string;
    currency_code: string;
    image: string;
    currency: {
      symbol: string;
      name: string;
      symbol_native: string;
      decimal_digits: number;
      rounding: number;
      code: string;
      name_plural: string;
    };
  };
}

export interface ExchangeRate {
  _key: string;
  _type: string;
  base_currency: string;
  rate: number;
}

export interface CurrencySettings {
  all_currencies: Pick<
    Currency,
    '_key' | '_type' | 'currency' | 'exchange_rates'
  >[];
  base_currencies: Pick<Currency, '_key' | '_type' | 'currency'>[] & {
    country: CountryCode;
  };
}

export interface CurrencySettingsGlobal {
  exchangeRates: {[x: string]: number};
  baseCurrency: string;
  all_currencies: Currency[];
  selectedCurrency: string;
}

export const getExchangeRates = (
  currencySettings: CurrencySettings,
  baseCurrency: string,
) =>
  currencySettings.all_currencies.map((c) => {
    const currency = c.currency.split('-')[1];
    const exchangeRate = c.exchange_rates.find(
      (er) => er.base_currency.split('-')[1] === baseCurrency,
    )?.rate;
    return {
      currency,
      rate: exchangeRate,
    };
  });

export const getExchangeRatesMap = (exchangeRates: any) =>
  exchangeRates.reduce(
    (
      a: {currency: string; rate: number}[],
      v: {currency: string; rate: number},
    ) => ({
      ...a,
      [v.currency]: v.rate,
    }),
    {},
  );

interface formatCurrencyProps {
  price: MoneyV2;
  baseCurrency: string;
  exchangeRates: {[x: string]: number};
  selectedCurrency: string;
}

export const formatCurrency = ({
  price,
  baseCurrency,
  exchangeRates,
  selectedCurrency,
}: formatCurrencyProps) => {
  fx.base = baseCurrency;
  fx.rates = exchangeRates;
  return fx.convert(price.amount, {
    from: baseCurrency ?? 'HKD',
    to: selectedCurrency,
  });
};
